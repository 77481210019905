import GridNavigation from './sub-navigation/GridNavigation';
import ImageListNavigation from './sub-navigation/ImageListNavigation';
import LinkListNavigation from './sub-navigation/LinkListNavigation';
import PropTypes from 'prop-types';
import classNames from '../../../utils/class-names';
import { getTestVariant } from '/services/ab-test';
import { useEffect, useState } from 'react';

const SubNavigation = ({ menu, show = false }) => {
	const [variantKey, setVariantKey] = useState();

	useEffect(() => {
		async function fetchVariant() {
			const variation = await getTestVariant('desktop-nav-images');
			setVariantKey(variation.key);
		}
		if (menu.href === '/pages/theme-party-collection') {
			fetchVariant();
		}
	}, []);
	let LinkComponent = LinkListNavigation;
	if (variantKey === 'show-images') {
		LinkComponent = ImageListNavigation;
	}

	return (
		<div
			className={classNames(
				'bg-v2-off-white border-b fixed flex justify-around left-0 right-0 shadow-2xl z-50',
				!show && 'top-[-600px]',
			)}>
			{menu.menuType === 'link-list' && <LinkComponent menu={menu} />}
			{menu.menuType === 'image-grid' && <GridNavigation menu={menu} />}
		</div>
	);
};

SubNavigation.propTypes = {
	menu: PropTypes.object,
	show: PropTypes.bool,
};

export default SubNavigation;
