import PropTypes from 'prop-types';
import SidebarImage from './items/SidebarImage';

const SubNavigationSidebar = ({ sidebar }) => {
	if (!sidebar || sidebar.length === 0) {
		return null;
	}

	return (
		<div className="flex flex-col md:hidden 2xl:flex">
			{sidebar.map((item, itemIndex) => {
				return <SidebarImage item={item} key={itemIndex} typographyVariant="link-header" />;
			})}
		</div>
	);
};

SubNavigationSidebar.propTypes = {
	sidebar: PropTypes.array,
};

export default SubNavigationSidebar;
