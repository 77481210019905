import { arrayDifference } from '/utils/common-utils';
import { getEstimatedMoneyBack } from '/services/static/promotions/money-back';
import { getRemotePromotionConfig } from '/services/promotions';
import { getVariantInventory } from '/services/products';
import { logTryCatch } from '/utils/logging';
import {
	PROMOTIONAL_TIER,
	PROMOTION_MONEY_BACK_ESTIMATE,
	PROMOTION_TYPE,
} from '/services/static/attribute-keys';

/**
 * finds existing line items in the cart that match tier ids (used for item removal)
 * @param {array} lines - lines in the cart
 * @param {array} tierTitles - tier ids to look for
 * @returns array of line items that match the tier ids supplied
 */

const joinedFreeGift = {
	sourceProducts: ['gid://shopify/Product/7401834479685', 'gid://shopify/Product/7401834545221'],
	freeItem: 'gid://shopify/ProductVariant/42005854289989',
};

const findLineItems = (lines, tierTitles) => {
	if (!tierTitles || tierTitles.length === 0) {
		return [];
	}

	return lines.reduce((memo, line) => {
		if (!line?.node?.attributes) {
			return false;
		}

		const promotionalAttribute = line.node.attributes.find((attribute) => {
			return attribute.key === PROMOTIONAL_TIER && tierTitles.includes(attribute.value);
		});

		if (promotionalAttribute) {
			memo.push(line.node.id);
		}

		return memo;
	}, []);
};

/**
 * finds tiers the user qualifies for (used for item addition)
 * @param {array} validTiers - valid tiers from the config
 * @param {array} tierTitles - tier ids to look for
 * @returns an array of products the user qualifies for
 */
const findPromotionalProducts = (validTiers, tierTitles) => {
	return validTiers.reduce(
		(memo, tier) => {
			if (!tierTitles.includes(tier.title)) {
				return memo;
			}

			// the user needs to select a product
			if (tier.selectProduct === true) {
				memo.interactionRequired.push(tier);
			}

			// the user does not need to select a product
			if (tier.selectProduct === false) {
				const autoAddProducts = tier.productConfigs.map((product) => {
					product.tierTitle = tier.title;
					return product;
				});

				memo.autoAdd = [...memo.autoAdd, ...autoAddProducts];
				memo.autoAddTier.push(tier);
			}

			return memo;
		},
		{ autoAdd: [], autoAddTier: [], interactionRequired: [] },
	);
};

const checkPromotionalInventory = async (tier) => {
	const results = await tier.productConfigs.reduce(
		async (memo, product) => {
			const inventory = await getVariantInventory(product.primary.variantId);

			if (inventory?.data?.node?.availableForSale === true) {
				product.tierTitle = tier.title;
				memo.autoAdd.push({ ...{ tierTitle: product.tierTitle }, ...product.primary });
			} else {
				for (let i = 0; i < product.backups?.length; i++) {
					if (product.backups[i].hasOptions) {
						memo.interactionRequired = true;
						break;
					} else {
						let inv = await getVariantInventory(product.backups[0].variantId);
						if (inv?.data?.node?.availableForSale) {
							product.backups[i].tierId = tier.id;
							memo.autoAdd.push({ ...{ tierTitle: product.tierTitle }, ...product.backups[i] });
							break;
						}
					}
				}
			}
			return memo;
		},
		{ autoAdd: [], interactionRequired: false, tier: tier },
	);
	return results;
};

const getPromotionCartAttributes = async (processedCart) => {
	const currentPromotion = await getRemotePromotionConfig();

	if (!currentPromotion) {
		return [];
	}

	const attributes = {};
	attributes[PROMOTION_TYPE] = currentPromotion.promotionType;

	if (currentPromotion.promotionType === 'money-back') {
		const moneyBack = getEstimatedMoneyBack(processedCart.subTotal);
		attributes[PROMOTION_MONEY_BACK_ESTIMATE] = moneyBack.estimate;
	}

	return [
		{
			key: '__promotion_info',
			value: JSON.stringify(attributes),
		},
	];
};

/**
 * run the current promotions on a cart
 * @param {object} cart - the current cart
 * @returns an object with items to add (config) and items to remove (line item ids)
 */
const runPromotions = async (cart) => {
	let lineItemsToRemove = [];
	// no cart, no cart lines -- early exit
	const lines = cart?.shopifyCart?.lines?.edges;
	if (!lines) {
		return;
	}

	const promotion = await getRemotePromotionConfig();

	//
	// look at the cart and get promotional info:
	//  - tierTitles - the current tier ids in the cart, driven off line item attributes
	//
	const promotionalInfo = lines.reduce(
		(memo, line) => {
			if (!line || !line.node) {
				return memo;
			}

			const promotionalTierAttribute = line.node.attributes.find((attribute) => {
				return attribute.key === PROMOTIONAL_TIER;
			});

			if (promotionalTierAttribute) {
				memo.tierTitles.push(promotionalTierAttribute.value);
			}

			return memo;
		},
		{ tierTitles: [] },
	);

	// this code is for when we want always on free gifts - commenting for simplicity
	// lines.map((lineItem) => {
	// 	const attributes = {};
	// 	lineItem.node.attributes.map((item) => {
	// 		attributes[item.key] = item.value;
	// 	});

	// 	if (attributes[ALWAYS_ON_FREE_GIFT]) {
	// 		if (
	// 			attributes[TIER_PRICE] < parseFloat(cart.shopifyCart.estimatedCost.subtotalAmount.amount)
	// 		) {
	// 			return;
	// 		} else {
	// 			lineItemsToRemove.push(lineItem.node.id);
	// 		}
	// 	}
	// });

	if (promotion && promotion.promotionType === 'stacking-gifts') {
		let subtotal = 0;

		try {
			subtotal = parseFloat(cart.shopifyCart.estimatedCost.subtotalAmount.amount);
		} catch (e) {
			logTryCatch('could not parse cart subtotal for promotions.');
		}

		// find the valid tiers for the promotionSubtotal
		const validTiers = promotion.tiers?.reduce(
			(memo, tier) => {
				if (tier.priceMin <= subtotal) {
					memo.tiers.push(tier);
					memo.tierTitles.push(tier.title);
				}
				return memo;
			},
			{ tierTitles: [], tiers: [] },
		);

		// if (!validTiers || validTiers.tiers.length === 0) {
		// 	return {};
		// }

		// look at the intersection of what exists in the cart and what the user should have
		const tiersToRemove = arrayDifference(promotionalInfo.tierTitles, validTiers.tierTitles);
		const tiersToAdd = arrayDifference(validTiers.tierTitles, promotionalInfo.tierTitles);
		lineItemsToRemove = findLineItems(lines, tiersToRemove);

		const promotionalProductsToAdd = findPromotionalProducts(validTiers.tiers, tiersToAdd);

		return {
			lineItemsToRemove,
			promotionalProductsToAdd,
		};
	}

	// if the promotion is not stacking gifts, always return the lineItemsToRemove so we clear the cart
	// const tiersInCart = promotionalInfo.tierTitles;
	// lineItemsToRemove = findLineItems(lines, tiersInCart);
	return { lineItemsToRemove };
};

const getNextTier = async (cartSubtotal) => {
	const currentPromotion = await getRemotePromotionConfig();

	if (!currentPromotion || !currentPromotion.tiers || !currentPromotion.tiers.length === 0) {
		return;
	}

	if (cartSubtotal === 0) {
		const firstTier = currentPromotion.tiers[0];
		return {
			hasNextTier: true,
			tier: firstTier,
			priceDifference: firstTier.priceMin,
		};
	}

	let currentTierIndex;

	currentPromotion.tiers.forEach((tier, i) => {
		if (tier.priceMin <= cartSubtotal) {
			currentTierIndex = i;
		}
	});

	const nextTier = currentPromotion.tiers[currentTierIndex + 1];

	if (!nextTier) {
		return {
			hasNextTier: false,
		};
	}

	const priceDifference = nextTier.priceMin - cartSubtotal;

	return {
		hasNextTier: priceDifference > 0,
		tier: nextTier,
		priceDifference,
	};
};

const runJoinedGifts = (cart) => {
	if (!cart?.shopifyCart?.lines?.edges) {
		return {
			lineItemsToRemove: [],
			lineItemsToAdd: [],
		};
	}

	const lines = cart.shopifyCart.lines.edges;

	const hasSourceProduct = lines.some((line) =>
		joinedFreeGift?.sourceProducts?.includes(line?.node?.merchandise?.product?.id),
	);

	const freeItemLine = lines.find(
		(line) => line?.node?.merchandise?.id === joinedFreeGift.freeItem,
	);

	if (!hasSourceProduct && freeItemLine) {
		return {
			lineItemsToRemove: [freeItemLine.node.id],
			lineItemsToAdd: [],
		};
	}

	if (hasSourceProduct && !freeItemLine) {
		return {
			lineItemsToRemove: [],
			lineItemsToAdd: [joinedFreeGift.freeItem],
		};
	}

	return {
		lineItemsToRemove: [],
		lineItemsToAdd: [],
	};
};

module.exports = {
	checkPromotionalInventory,
	getNextTier,
	getPromotionCartAttributes,
	runJoinedGifts,
	runPromotions,
};
