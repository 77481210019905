import Clickable from '../../../../../Clickable';
import Image from '../../../../../Image';
import Loading from '../../../../../Loading';
import { MENU_UPSELL } from '../../../../../../services/static/attribute-keys';
import Price from '../../../../../Price';
import PropTypes from 'prop-types';
import SelectGroup from '../../../../../SelectGroup';
import Typography from '../../../../../Typography';

import { logTryCatch } from '../../../../../../utils/logging';
import { useCartStore } from '../../../../../../state/cart-context';
import { useState } from 'react';

import { getProductOptions, linesFromVariants } from '../../../../../../utils/product';

const Product = ({ product }) => {
	const cart = useCartStore();
	const [selectedOption, setSelectedOption] = useState();
	const [selectedTitle, setSelectedTitle] = useState('');
	const [addToCartLoading, setAddToCartLoading] = useState(false);

	const addToCart = async (variantId) => {
		setAddToCartLoading(true);

		const line = {
			variantId,
			quantity: 1,
			attributes: [{ key: MENU_UPSELL, value: 'true' }],
		};

		const lines = linesFromVariants([line]);

		const analyticsData = {
			price: product.upsellPrice || product.prices[0],
			product: product,
			quantity: 1,
		};

		const variant = product.variants.find((variant) => {
			return variant.id === variantId;
		});

		if (variant) {
			analyticsData.variantId = variant.id;
			analyticsData.variantSku = variant.sku;
			analyticsData.variantTitle = selectedTitle;
		}

		await cart
			.addItems({
				analytics: [analyticsData],
				error: () => {
					setAddToCartLoading(false);
				},
				lines: lines,
				success: () => {
					setAddToCartLoading(false);
				},
			})
			.catch((e) => {
				setAddToCartLoading(false);
				logTryCatch(e);
			});
	};

	return (
		<div className="h-[455px] px-8">
			<div className="flex flex-col justify-center gap-2 border rounded-xl h-full p-4 items-center mx-2">
				<div className="m-4">
					<Image
						src={product.images[0].src}
						alt={product.title}
						height={200}
						width={200}
						objectFit="contain"
					/>
				</div>
				<div className="w-full">
					<Typography
						font="lexend"
						variant="body-lg"
						component="div"
						className="text-center uppercase min-h-[60px] flex items-center justify-center">
						{product.titles[0]}
					</Typography>
					<div className="flex justify-center items-center">
						{product.upsellPrice && (
							<>
								<Typography className="line-through my-2 mr-2" variant="small">
									<Price price={product.prices[0]} />
								</Typography>
								<Typography className="text-primary my-2" variant="small">
									<Price price={product.upsellPrice} />
								</Typography>
							</>
						)}
						{!product.upsellPrice && (
							<Typography className="my-2" component="div" variant="body">
								<Price price={product.prices[0]} />
							</Typography>
						)}
					</div>
					{product.variants && product.variants[0] && (
						<SelectGroup
							className="my-1"
							variant="short"
							defaultValue={selectedOption?.value}
							placeholder="Select..."
							name="Options"
							options={getProductOptions(product)}
							onChange={(val, title) => {
								setSelectedOption(val);
								setSelectedTitle(title);
							}}
						/>
					)}
					<div className="mt-1 mb-6">
						<Clickable
							variant="filled"
							className="flex items-center justify-center w-full"
							size="sm"
							disabled={!selectedOption}
							onClick={() => addToCart(selectedOption)}
							heapEventName="Nav Bar Upsell Add To Cart"
							heapEventData={{
								product: product?.handle,
							}}>
							<Typography font="lexend" component="p" variant="body">
								Add to Cart
							</Typography>
							{addToCartLoading && <Loading height="16px" width="16px" className="ml-2" />}
						</Clickable>
					</div>
				</div>
			</div>
		</div>
	);
};

Product.propTypes = {
	product: PropTypes.object,
};

export default Product;
