import MeasurementTable from './MeasurementTable';
import PropTypes from 'prop-types';
import SelectGroup from '../SelectGroup';
import Typography from '../Typography';

import { pluck } from '/utils/common-utils';

import { useEffect, useState } from 'react';

const MeasurementTables = ({ note, tables }) => {
	const [filteredTables, setFilteredTables] = useState([]);
	const [hasOneTableType, setHasOneTableType] = useState(true);
	const [selectedTableType, setSelectedTableType] = useState();
	const [tableTypeOptions, setTableTypeOptions] = useState([]);

	const optionsRenameMap = {
		default: 'US',
		international: 'International',
	};

	useEffect(() => {
		const tableKeys = pluck(tables, 'key');

		// something is probably wrong, the tables don't have keys
		if (tableKeys.length === 0) {
			return;
		}

		updateSelectedTableType(tableKeys[0]);

		// the table only has one key type, so dont show the dropdown
		if (tableKeys.length === 1) {
			setHasOneTableType(true);
			return;
		}

		// the table has multiple key types, populate the dropdown and show it
		setHasOneTableType(false);
		setTableTypeOptions(
			tableKeys.map((k) => {
				return { value: k, label: optionsRenameMap[k] || k };
			}),
		);
	}, [tables]);

	const updateSelectedTableType = (newKey) => {
		setSelectedTableType(newKey);

		const filtered = tables.filter((table) => {
			return table.key === newKey;
		});

		setFilteredTables(filtered);
	};

	// there are no tables, don't show anything.
	if (!filteredTables || filteredTables.length === 0) {
		return null;
	}

	return (
		<div className="w-full text-center px-4 md:pt-4">
			{note && (
				<Typography component="p" variant="body" className="md:pb-4">
					{note}
				</Typography>
			)}
			{!hasOneTableType && (
				<SelectGroup
					defaultValue={selectedTableType || ''}
					name=""
					onChange={(val) => {
						updateSelectedTableType(val);
					}}
					options={tableTypeOptions}
					variant="medium"
				/>
			)}
			{filteredTables &&
				filteredTables.map((table, i) => {
					return <MeasurementTable key={i} tableDetails={table.measurementTable} />;
				})}
		</div>
	);
};

MeasurementTables.propTypes = {
	note: PropTypes.string,
	tables: PropTypes.array,
};

export default MeasurementTables;
