import Price from '../Price';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { checkProductTags } from '/utils/product-tags';
import { getSubscriptionForProductType } from '/services/static/subscription/subscriptions-ordergroove';
import { observer } from 'mobx-react-lite';
import { useDiscountCodeStore } from '../../state/discount-code-context';
import { useEffect, useState } from 'react';

const ProductCardBanner = ({ product }) => {
	const [isSubscribable, setIsSubscribable] = useState(false);
	const [discountSubscriptionPrice, setDiscountSubscriptionPrice] = useState();

	const discountCodeStore = useDiscountCodeStore();
	const subscription = getSubscriptionForProductType(product.shopifyProductType);
	const defaultMonthlyPrice = subscription?.frequencies?.find(
		(freq) => freq.key === 'monthly',
	)?.price;

	useEffect(() => {
		const productSubscribable = checkProductTags(product.tags, 'Show Subscribe Option');
		const productType = product?.shopifyProductType || product?.productType;
		setIsSubscribable(productSubscribable);

		if (!discountCodeStore || !productSubscribable) {
			return;
		}

		const discountCodeConfig = discountCodeStore.getCurrentDiscountCodeConfig(productType);
		setDiscountSubscriptionPrice(discountCodeConfig?.subscriptionPrice);
	}, [discountCodeStore?.currentDiscountCode, product?.shopifyProductType, product?.productType]);

	if (!isSubscribable) {
		return null;
	}

	if (defaultMonthlyPrice && !discountSubscriptionPrice) {
		return (
			<Typography className="text-primary md:mx-1" component="p">
				(<Price price={defaultMonthlyPrice} /> On Subscription)
			</Typography>
		);
	}

	if (!discountSubscriptionPrice) {
		return null;
	}

	return (
		<Typography className="text-primary md:mx-1" component="p">
			Try for <Price price={discountSubscriptionPrice} />
		</Typography>
	);
};

export default observer(ProductCardBanner);

ProductCardBanner.propTypes = {
	product: PropTypes.object,
};
