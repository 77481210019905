import Button from '/components/Button';
import CartNavButton from '/components/top-nav/CartNavButton';
import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import Link from 'next/link';
import NavMenu from '/components/top-nav/mobile-menu/NavMenu';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { observer } from 'mobx-react-lite';
import { useGlobalStore } from '/state/global-context';
import { useState } from 'react';

const SearchPanel = dynamic(() => import('/components/top-nav/SearchPanel'));

const MobileMenu = observer(() => {
	const globalStore = useGlobalStore();
	const navigation = globalStore.getNavigationConfig();

	const [open, setOpen] = useState(false);

	const [searchActive, setSearchActive] = useState(false);

	return (
		<div className="bg-v2-off-white border-b h-14 flex relative w-full lg:hidden justify-between px-2">
			<Link href="/" alt="Shinesty" passHref>
				<a className="flex items-center pl-2">
					<Icon height="32px" name="shinesty" />
				</a>
			</Link>
			<div className="flex flex-grow justify-end px-2 gap-2">
				<Button
					className="flex-none block px-0"
					onClick={() => setSearchActive(true)}
					size="sm"
					title="Open Search Panel"
					variant="naked">
					<Icon height="24px" name="search" width="24px" />
				</Button>

				<CartNavButton />
				<Clickable
					className="flex-none block px-0"
					heapEventName="Mobile Navigation Open"
					onClick={() => setOpen(true)}
					title="Open Navigation Menu"
					variant="naked">
					<Icon height="20px" name="menu" width="20px" />
				</Clickable>
			</div>

			{searchActive && (
				<SearchPanel closeCallback={() => setSearchActive(false)} isOpen={searchActive} />
			)}

			{!searchActive && (
				<NavMenu
					closeNavigation={() => {
						setOpen(false);
					}}
					footer={
						<>
							<div className="mb-2 w-full">
								<Link href="https://help.shinesty.com" passHref>
									<Button full variant="naked" className="text-[18px] uppercase">
										help
									</Button>
								</Link>
							</div>
							<Button full>
								<Link href="https://account.shinesty.com/account" passHref>
									<a className="uppercase">Log in to account</a>
								</Link>
							</Button>
						</>
					}
					isOpen={open}
					menus={navigation.menus}
					setOpen={setOpen}
				/>
			)}
		</div>
	);
});

MobileMenu.propTypes = {
	cart: PropTypes.object.isRequired,
	footer: PropTypes.element,
};
export default MobileMenu;
