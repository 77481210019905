'use server';

import NextHead from 'next/head';
import PropTypes from 'prop-types';
import { reactChildren } from '/utils/prop-types';
import { useRouter } from 'next/router';

const Head = ({
	children,
	description,
	descriptionOg,
	metaRobots,
	socialImageUrl,
	socialImageHeight,
	socialImageWidth,
	title,
	type,
}) => {
	const router = useRouter();
	const path = router?.asPath ?? '';
	// const includePixels = path !== '/cart-clear';
	const indexOfQueryParams = path.indexOf('?');
	const canonicalPath = indexOfQueryParams !== -1 ? path.slice(0, indexOfQueryParams) : path;

	// this component is for any and all dynamic head content, including defaults;
	// see _document.jsx for static head content

	// this component is used in layouts/main.jsx to establish defaults,
	// and in individual pages to overwrite defaults with page-specific content

	// key property is used on tags to prevent duplicates (https://nextjs.org/docs/api-reference/next/head)
	return (
		<NextHead>
			{metaRobots ? <meta name="robots" content={metaRobots} /> : null}

			<meta key="description" name="description" content={description} />

			<meta key="og:description" property="og:description" content={descriptionOg} />
			<meta
				key="og:image"
				property="og:image"
				content={
					socialImageUrl ||
					'https://cdn.shopify.com/s/files/1/0234/5963/files/RedSquareLogo.png?210436'
				}
			/>
			<meta key="og:image:height" property="og:image:height" content={socialImageHeight} />
			<meta key="og:image:width" property="og:image:width" content={socialImageWidth} />
			<meta key="og:title" property="og:title" content={title} />
			<meta key="og:url" property="og:url" content={`https://shinesty.com${path}`} />
			<meta key="og:type" property="og:type" content={type} />

			<meta key="twitter:card" name="twitter:card" content="summary" />
			<meta key="twitter:creator" name="twitter:creator" content="@Shinesty" />
			{/* <meta key="twitter:description" name="twitter:description" content={description} /> */}
			<meta key="twitter:site" name="twitter:site" content="@Shinesty" />
			{/* <meta key="twitter:title" name="twitter:title" content={title} /> */}
			<meta name="google-site-verification" content="3FlTrsmV4xZEFiELxXy_K58Hy_fvBYP9ZDdP--8p1cY" />
			<meta name="author" content="Shinesty" />
			<meta name="HandheldFriendly" content="true" />
			<meta name="MobileOptimized" content="320" />
			<link key="canonical" rel="canonical" href={`https://www.shinesty.com${canonicalPath}`} />
			<link
				rel="shortcut icon"
				href="https://cdn.shinesty.com/2023-05-08/shinesty-favicon.svg"
				type="image/svg+xml"
			/>
			{/* <link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
			<link
				href="https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap&text=1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz%20%21%22%23%24%25%26%27™®"
				rel="stylesheet"></link> */}

			<title key="title">{title}</title>
			{/* <script id="tatari-sitewide" strategy="afterInteractive">
				{includePixels &&
					`
						!function(){try{!function(t,i){if(!i.version){window.tatari=i,i.init=function(t,n){var e=function(t,n){i[n]=function(){t.push([n].concat(Array.prototype.slice.call(arguments,0)))}};"track pageview identify".split(" ").forEach(function(t){e(i,t)}),i._i=t,i.config=n,i.pageview()},i.version="1.2.1";var n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://d2hrivdxn8ekm8.cloudfront.net/tag-manager/fbe854b3-27fd-453c-8c93-5e2e2dfe7ce3-latest.js";var e=t.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)}}(document,window.tatari||[])}catch(t){console.log(t)}}(); tatari.init('fbe854b3-27fd-453c-8c93-5e2e2dfe7ce3');
				`}
			</script> */}
			{children}
		</NextHead>
	);
};

Head.propTypes = {
	children: reactChildren,
	description: PropTypes.string,
	descriptionOg: PropTypes.string,
	metaRobots: PropTypes.string,
	socialImageHeight: PropTypes.string,
	socialImageUrl: PropTypes.string,
	socialImageWidth: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.string,
};

Head.defaultProps = {
	description:
		"Keeping you outfitted for all of life's social moments, welcome to the home of the largest collection of outrageous party clothing and themed party apparel on the internet. Your life just got way better.",
	descriptionOg:
		'Shop Shinesty for the most comfortable underwear on the internet. Our signature Ball Hammock® pouch underwear is the best men’s underwear money can buy. Don’t love ’em? They’re free. Only at Shinesty.',
	metaRobots: 'index,follow',
	socialImageHeight: '250',
	socialImageWidth: '250',
	title: 'Shinesty | Home of Ball Hammock® Pouch Underwear',
	type: 'website',
};

export default Head;
