import Clickable from '/components/Clickable';
import PropTypes from 'prop-types';
import SubNavigation from './SubNavigation';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getItemHref } from '../utils';
import { useState } from 'react';

export default function NavLink({ menu }) {
	const [showChildren, setShowChildren] = useState(false);

	const href = getItemHref(menu);

	return (
		<div
			onFocus={() => {
				if (menu.children) {
					setShowChildren(true);
				}
			}}
			onBlur={() => setShowChildren(false)}
			onMouseEnter={() => {
				if (menu.children) {
					setShowChildren(true);
				}
			}}
			onMouseLeave={() => setShowChildren(false)}
			onClick={() => {
				setShowChildren(false);
			}}>
			{href && (
				<Clickable
					linkToInternal={href}
					heapEventName="Navigation Link Click"
					heapEventData={{
						Href: href,
						Position: 'Tier 1',
					}}
					className={classNames(
						'block focus:decoration-2 focus:decoration-primary focus:underline focus:underline-offset-4 font-bold h-20 hover:decoration-2 hover:decoration-primary hover:underline hover:underline-offset-4 mx-3 pb-[29px] px-1 pt-[30px] relative text-primary text-sm tracking-widest z-50',
						menu?.style === 'bold' && 'focus:decoration-black hover:decoration-black !text-black ',
					)}>
					<Typography font="lexend" variant="subhead-xs">
						{menu.title}
					</Typography>
				</Clickable>
			)}

			<SubNavigation menu={menu} show={showChildren} />
		</div>
	);
}

NavLink.propTypes = {
	menu: PropTypes.object.isRequired,
};
