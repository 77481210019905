import { gql } from '@apollo/client';

const GET_PRODUCT_PRICE = gql`
	query GetProduct($productId: ID!) {
		product(id: $productId) {
			priceRange {
				minVariantPrice {
					amount
				}
				maxVariantPrice {
					amount
				}
			}
			compareAtPriceRange {
				minVariantPrice {
					amount
				}
				maxVariantPrice {
					amount
				}
			}
		}
	}
`;

export default GET_PRODUCT_PRICE;
