import { parseGid } from '/utils/graph-ql';

export const formatProductsAxon = (products) => {
	return products.map((product) => {
		// per axon on slack if no product id default to a hidden one
		const idToUse = product?.shopifyProductId
			? parseGid(product.shopifyProductId)
			: '6785612873797';
		const axonProduct = {
			item_id: idToUse,
			item_name: product.title,
			price: parseFloat(product.price),
			quantity: product?.quantity || 1,
			item_category: product.productType,
			image_url: product.image,
			item_brand: 'Shinesty',
		};

		if (product.shopifyVariantId) {
			axonProduct.item_variant = parseGid(product.shopifyVariantId);
		}

		return axonProduct;
	});
};
