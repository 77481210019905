import CartNavButton from '/components/top-nav/CartNavButton';
import Icon from '/components/IconUpdated';
import Link from 'next/link';
import NavLink from '/components/top-nav/desktop-menu/NavLink';
import ProductSearch from '/components/top-nav/desktop-menu/ProductSearch';
import PropTypes from 'prop-types';
import SitewideBanner from '/components/discount-codes/SitewideBanner';
import { useGlobalStore } from '/state/global-context';

const DesktopMenu = () => {
	const globalStore = useGlobalStore();
	const navigation = globalStore.getNavigationConfig();

	return (
		<div>
			<SitewideBanner />
			<div className="bg-v2-off-white  h-20 hidden lg:flex">
				<div className="flex m-6">
					<Link href="/" alt="Shinesty" passHref>
						<a>
							<Icon name="shinesty" height="32px" width="auto" />
						</a>
					</Link>
				</div>
				<div className="flex-1">
					<div className="hidden items-stretch lg:flex">
						{navigation.menus && navigation.menus.length > 0 && (
							<>
								{navigation.menus.map((menu, menuIndex) => {
									return <NavLink key={menuIndex} menu={menu} />;
								})}
							</>
						)}
					</div>
				</div>
				<div className="flex-none mt-4 mx-4">
					<ProductSearch />
				</div>
				<div className="flex-none mx-2 my-7">
					<a href="https://account.shinesty.com/account" title="Sign in to account">
						<Icon name="user" height="24px" width="24px" />
					</a>
				</div>
				<div className="flex-none mx-2 my-5 relative">
					<CartNavButton variant="desktop" />
				</div>
			</div>
		</div>
	);
};

DesktopMenu.propTypes = {
	cart: PropTypes.object.isRequired,
};

export default DesktopMenu;
