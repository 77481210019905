import Algolia from '/services/algolia/';
import Icon from '/components/IconUpdated';
import InputGroup from '/components/InputGroup';
import Link from 'next/link';
import ProductCard from '/components/product-card/ProductCard';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import debounce from '/utils/debounce';
import { useGlobalStore } from '/state/global-context';
import { useRouter } from 'next/router';

import { useCallback, useEffect, useRef, useState } from 'react';

export default function ProductSearch() {
	const globalStore = useGlobalStore();
	const router = useRouter();

	const [products, setProducts] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [showSearch, setShowSearch] = useState(false);
	const inputRef = useRef(null);
	const [top, setTop] = useState(globalStore.topPosition);

	const searchProducts = async (val) => {
		if (val.length > 0) {
			const { hits } = await Algolia.getCollectionProducts({
				searchTerm: val,
				collectionHandle: null,
				page: 0,
				pageSize: 6,
			});

			setProducts(hits);
		} else {
			setProducts([]);
		}
	};

	const handleSearchChange = useCallback(debounce(searchProducts, 500), []);

	const setSearchVisible = () => {
		setShowSearch(true);

		if (inputRef.current) {
			setTimeout(() => inputRef.current.focus(), 100);
		}
	};

	useEffect(() => {
		setTop(globalStore.topPosition);
	}, [globalStore.topPosition]);

	const submitSearchForm = (event) => {
		event.preventDefault();
		setShowSearch(false);
		router.push({
			pathname: '/search',
			query: { q: searchTerm },
		});
	};

	return (
		<div className={classNames('relative  ', showSearch ? 'w-60' : 'w-12')}>
			<form onSubmit={submitSearchForm} label="search">
				<button
					title="open search input"
					className="absolute top-[13px] left-2 w-6 rounded-full"
					type="button"
					onClick={setSearchVisible}>
					<Icon name="search" height="24px" width="24px" />
				</button>
				<InputGroup
					ref={inputRef}
					type="text"
					className={classNames(
						'border rounded-full border-primary placeholder-primary px-10 bg-v2-off-white',
						showSearch ? '' : 'hidden',
					)}
					adaTitle="Search Shinesty"
					placeholder="Ask Jeeves..."
					value={searchTerm}
					onChange={(val) => {
						setSearchTerm(val);
						handleSearchChange(val);
					}}
				/>

				{showSearch && (
					<button
						title="close search input"
						label="close search"
						className="absolute top-[18px] right-0 text-primary text-2xl px-3"
						onClick={() => {
							setShowSearch(false);
							setProducts([]);
							setSearchTerm('');
						}}
						type="button">
						<Icon name="close" height="14px" width="14px" />
					</button>
				)}
			</form>
			{showSearch && products.length == 0 && searchTerm != '' && (
				<div
					className={`bg-v2-off-white border border-gray-300 fixed flex flex-col flex-wrap h-full lg:h-auto justify-center overflow-auto p-3 right-0 rounded-sm ${top} w-full z-30 mt-3`}>
					<Typography className="flex-grow text-center" variant="subtitle-lg">
						Don&apos;t see what you&apos;re looking for?
					</Typography>
					<Link href="/collections/guys-boxers" passHref>
						<a className="flex-grow text-center" onClick={() => setShowSearch(false)}>
							<Typography className="text-primary" variant="subtitle-lg">
								Shop best sellers
							</Typography>
						</a>
					</Link>
				</div>
			)}
			{showSearch && products.length > 0 && (
				<div
					className={`bg-v2-off-white border border-gray-300 fixed h-auto p-3 right-0 rounded-sm ${top} w-full z-30 mt-3 shadow-2xl`}>
					<div className="grid grid-cols-2 md:grid-cols-6 gap-2">
						{products.map((product, i) => {
							return (
								<ProductCard
									heapLocation="algolia-autocomplete"
									key={i}
									onClick={() => setShowSearch(false)}
									product={product}
									priority={false}
									rank={i}
									showQuickShop={false}
								/>
							);
						})}
					</div>
					<div className="text-center">
						<Link href={`/search?q=${searchTerm}`}>
							<a onClick={() => setShowSearch(false)}>
								<Typography className="text-primary" component="p" variant="body">
									See All
								</Typography>
							</a>
						</Link>
					</div>
				</div>
			)}
		</div>
	);
}
