import ProductCardQuickAdd from '/components/collections/ProductCardQuickAdd';
import PropTypes from 'prop-types';
import { useMediaQuery } from '/hooks/useMediaQuery';

const ProductCardAction = ({ className, product, showQuickAdd, setShowQuickAdd }) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<>
			{showQuickAdd && (
				<ProductCardQuickAdd
					className={className}
					product={product}
					isMobile={isMobile}
					onClose={setShowQuickAdd}
				/>
			)}
		</>
	);
};

ProductCardAction.propTypes = {
	className: PropTypes.string,
	product: PropTypes.object,
	setShowQuickAdd: PropTypes.func,
	showQuickAdd: PropTypes.bool,
};

export default ProductCardAction;
