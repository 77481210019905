import Clickable from '/components/Clickable';
import CountryFunctionality from '../CountryFunctionality';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import Loading from '/components/Loading';
// import Price from '../Price';
import ProductOptionsStandard from '/components/products/show/product-form/ProductOptionsStandard';
import PropTypes from 'prop-types';
import RadioGroup from '/components/RadioGroup';
// import Toggle from 'react-toggle';
import { Tooltip } from 'react-tooltip';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import dynamic from 'next/dynamic';
import { getButtonImageSrc } from '/services/products';
import { getCollection } from '/services/algolia';
import { getCompleteProductById } from '/services/products';
import { getSubscriptionData } from '/services/static/subscription/subscriptions-ordergroove';
import { linesFromVariants } from '/utils/product';
import { parseGid } from '/utils/graph-ql';
import { useDiscountCodeStore } from '/state/discount-code-context';
import { useGlobalStore } from '/state/global-context';
import {
	CAN_CHANGE_QUANTITY,
	DISCOUNTED_LINE_PRICE,
	GIFT_ID,
	IS_FREE_ITEM,
	PROMOTIONAL_ITEM,
	PROMOTIONAL_ITEM_INTERACTION,
} from '/services/static/attribute-keys';

import { useEffect, useState } from 'react';
const Modal = dynamic(() => import('/components/Modal'));
const ModalBody = dynamic(() => import('/components/Modal').then((mod) => mod.ModalBody));

export default function FreeItemCartDetail({ cart, processedCart }) {
	const discountCodeStore = useDiscountCodeStore();
	const globalStore = useGlobalStore();
	const currentPromotion = globalStore.getPromotionalConfig();
	const [activeProductType, setActiveProductType] = useState('socks - crew');
	const [productModalOpen, setProductModalOpen] = useState(false);
	const [addToCartLoading, setAddToCartLoading] = useState(false);
	const [isSubscription, setIsSubscription] = useState(false);
	const [selectableProducts, setSelectableProducts] = useState([]);
	const [activeProduct, setActiveProduct] = useState();
	const [shopifyProduct, setShopifyProduct] = useState();
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [purchaseType, setPurchaseType] = useState('product');

	const cartHasFreeSock = processedCart.processedLineItems.find((item) => {
		return item.attributes[GIFT_ID] == 'freesock';
	});

	useEffect(async () => {
		if (!cartHasFreeSock && processedCart.subTotal > currentPromotion.minimumCartSubtotal) {
			setProductModalOpen(true);
		}
		if (cartHasFreeSock && processedCart.subTotal < currentPromotion.minimumCartSubtotal) {
			if (cartHasFreeSock) {
				await cart.removeItems({ lineIds: cartHasFreeSock.id });
			}
		}
	}, [processedCart]);

	useEffect(() => {
		async function getProducts() {
			const algoliaReponse = await getCollection('heel-hammock-super-stupid-soft-socks');

			const hits = algoliaReponse?.hits?.filter(
				(hit) => hit?.shopifyProductType?.includes('socks -') && !hit?.tags['nofreebie'],
			);
			setSelectableProducts(hits);
			setActiveProduct(hits[0]);
		}
		getProducts();
	}, []);

	useEffect(() => {
		setSelectedOptions([]);
		async function getProducts() {
			const { product } = await getCompleteProductById(parseGid(activeProduct.shopifyProductId));

			setShopifyProduct(product);
		}
		if (activeProduct?.id) {
			getProducts();
		}
	}, [activeProduct]);

	if (!currentPromotion || currentPromotion?.promotionType != 'cart-value') {
		return null;
	}

	const groupedProducts = selectableProducts.reduce(
		(memo, product) => {
			if (product.shopifyProductType == activeProductType) {
				memo.socks.push(product);
			}
			return memo;
		},
		{ socks: [] },
	);

	const planKey = 'monthly';

	async function addGift() {
		setAddToCartLoading(true);
		const selectedOption = selectedOptions && selectedOptions[0];
		let subscriptionData;

		const attributes = [
			{ key: PROMOTIONAL_ITEM, value: 'true' },
			{ key: CAN_CHANGE_QUANTITY, value: 'false' },
			{ key: GIFT_ID, value: 'freesock' },
			{ key: IS_FREE_ITEM, value: 'true' },
			{ key: DISCOUNTED_LINE_PRICE, value: '0.00' },
			{ key: PROMOTIONAL_ITEM_INTERACTION, value: 'true' },
		];

		if (isSubscription) {
			subscriptionData = getSubscriptionData({
				discountCodeStore: discountCodeStore,
				selections: [
					{
						image: activeProduct.image,
						productType: activeProduct.shopifyProductType,
						product_id: selectedOption.id,
						size: selectedOption.variantTitle,
						title: activeProduct?.title?.split(' | ')[0],
						variantId: selectedOption.variantId,
						variantSku: selectedOption.variantSku,
						sellingPlan: shopifyProduct.sellingPlans.find((plan) => {
							return plan.key == planKey || plan.frequency == planKey;
						}),
					},
				],
				sellingPlanId: shopifyProduct.sellingPlans.find((plan) => {
					return plan.key == planKey || plan.frequency == planKey;
				})?.id,
				frequencyKey: planKey,
				subscriptionType: 'single',
				isSocks: true,
				attributes: attributes,
				discountedSubPrice: '0.00',
			});
		}

		const line = {
			variantId: selectedOption.variantId,
			quantity: 1,
			attributes: attributes,
		};

		const lines = linesFromVariants([line]);

		if (processedCart) {
			if (cartHasFreeSock) {
				await cart.removeItems({ lineIds: cartHasFreeSock.id });
			}
		}

		await cart
			.addItems({
				analytics: subscriptionData?.analytics || [],
				error: () => {
					setAddToCartLoading(false);
				},
				lines: subscriptionData?.lineItems || lines,
				success: () => {
					setAddToCartLoading(false);
					setProductModalOpen(false);
				},
			})
			.catch(() => {
				setAddToCartLoading(false);
			});
	}

	const radios = [
		{
			value: 'product',
			label: (
				<span>
					One Time <span className="line-through text-primary">13.99</span> 0.00
				</span>
			),
		},
		{
			value: 'subscribe',
			label: (
				<span>
					Subscribe <span className="line-through text-primary">9.99</span> 0.00 + Free Shipping
					(Renews monthly at 9.99)
				</span>
			),
		},
	];

	return (
		<div
			className="text-center py-2"
			style={{
				backgroundColor: currentPromotion.sitewideBanner.colorPrimary,
				color: currentPromotion.sitewideBanner.colorSecondary,
			}}>
			<Typography variant="heading-sm" className="">
				right now, every order over $50 gets free socks
			</Typography>
			<Modal
				id="free-item"
				isOpen={productModalOpen}
				toggle={(val) => setProductModalOpen(val)}
				customType="stacking">
				<ModalBody>
					<div className="bg-v2-off-white mt-4 rounded-lg shoadow-lg p-4">
						<Typography variant="heading-sm">Oh Yeah, Your Order Gets Free Socks</Typography>
						<div>
							{activeProduct?.images[0] ? (
								<div className="grid pt-4">
									<Image
										src={activeProduct?.images[0]?.imageSrc}
										alt={activeProduct?.title}
										height={400}
										width={400}
										objectFit="contain"
									/>
								</div>
							) : (
								<div className="h-[420px] flex items-center align-middle justify-center">
									<Loading height="50px" width="50px" />
								</div>
							)}
							<CountryFunctionality functionalityKey="subscription">
								<div className="flex justify-left align-middle py-2 gap-2">
									<Typography variant="heading-xs">Purchase Type</Typography>

									<Tooltip id="my-tooltip" noArrow="true" />
									<a
										className="cursor-pointer"
										data-tooltip-id="my-tooltip"
										data-tooltip-html="Free sock applies to first month of subscription. Renews at 9.99 a month (29% savings)"
										data-tooltip-place="top">
										<Icon name="info-gray" height={18} width={18} />
									</a>
								</div>

								<RadioGroup
									name="free_gift_type"
									radios={radios}
									checkedValue={purchaseType}
									onChange={(value) => {
										setPurchaseType(value);
										setIsSubscription(value === 'subscribe');
									}}
								/>
							</CountryFunctionality>
							<Typography variant="heading-xs">Sock Type</Typography>
							<div className="flex gap-2 py-2">
								<button
									className={classNames(
										' rounded-[4px] text-center font-semibold px-6 uppercase tracking-wider h-[46px] leading-4 flex justify-center items-center',
										activeProductType == 'socks - crew' ? 'bg-gray-800 text-white' : 'bg-gray-200',
									)}
									onClick={() => setActiveProductType('socks - crew')}>
									Crew
								</button>
								<button
									className={classNames(
										' rounded-[4px] text-center font-semibold px-6 uppercase tracking-wider h-[46px] leading-4 flex justify-center items-center',
										activeProductType == 'socks - ankle' ? 'bg-gray-800 text-white' : 'bg-gray-200',
									)}
									onClick={() => setActiveProductType('socks - ankle')}>
									Ankle
								</button>
							</div>
							<Typography variant="heading-xs">Print</Typography>
							<div className="flex flex-wrap justify-left gap-3 p-1 pb-2">
								{groupedProducts.socks &&
									groupedProducts.socks.map((print, i) => {
										const image = getButtonImageSrc(print);
										if (image) {
											return (
												<div className="flex flex-col relative">
													<button
														onClick={() => setActiveProduct(print)}
														className={classNames(
															'rounded-full h-[50px] w-[50px] m-px',
															activeProduct?.handle == print.handle
																? 'border-2 border-white ring-4 ring-gray-600 rounded-full hover:ring-gray-600'
																: 'hover:ring-gray-400 hover:border-2 border-white hover:ring-4',
														)}
														key={i}>
														<Image
															className="rounded-full"
															src={image}
															height={200}
															width={200}
															objectFit="contain"
															alt="button image"
														/>
													</button>
												</div>
											);
										}
									})}
							</div>
							{shopifyProduct && (
								<ProductOptionsStandard
									activeHandle={shopifyProduct.handle}
									hasJoinedProducts={false}
									hasSingleSizeOption={false}
									joinedProducts={[]}
									product={shopifyProduct}
									selectedOptions={selectedOptions}
									setSelectedOptions={setSelectedOptions}
								/>
							)}
						</div>
						<div className="text-center flex flex-col">
							<Clickable
								variant="filled"
								onClick={() => addGift()}
								disabled={addToCartLoading || selectedOptions.length == 0}>
								{selectedOptions.length == 0 ? 'Select Size' : 'Add Free Socks'}
							</Clickable>
							<Clickable variant="naked" onClick={() => setProductModalOpen(false)}>
								I dont want free socks
							</Clickable>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}

FreeItemCartDetail.propTypes = {
	cart: PropTypes.object,
	processedCart: PropTypes.object,
};
