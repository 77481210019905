import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import PropTypes from 'prop-types';
import Transition from '/components/Transition';
import Typography from '/components/Typography';
import { booleanFormat } from '/utils/common-utils';
import { consent as consentBlotout } from '@blotoutio/edgetag-sdk-js';
import { useGlobalStore } from '/state/global-context';
import { Fragment, useEffect, useState } from 'react';
import { readLocalStorage, writeLocalStorage } from '/utils/local-storage';

const COOKIE_CONSENT_KEY = 'shinesty_consent_shown';

export default function CookiesAlert({ country, state }) {
	const [show, setShow] = useState(false);
	const globalStore = useGlobalStore();

	useEffect(() => {
		const cookieConsent = readLocalStorage(COOKIE_CONSENT_KEY);

		if (
			(!cookieConsent || cookieConsent === '') &&
			(!country || !state || country === '' || state === '' || country !== 'US' || state === 'CA')
		) {
			setShow(true);
		} else {
			globalStore.setCookieConsent(cookieConsent || true);
		}
	}, []);

	const handleSelection = (showCookies) => {
		globalStore.setCookieConsent(showCookies);
		consentBlotout({ all: booleanFormat(showCookies) });
		setShow(false);
		writeLocalStorage(COOKIE_CONSENT_KEY, showCookies);
	};

	return (
		<div
			aria-live="assertive"
			className="pointer-events-none fixed inset-x-0 bottom-0 flex items-end px-4 pt-4 pb-4 sm:pb-6 sm:items-start z-20">
			<div className="flex w-full flex-col items-center space-y-4">
				<Transition
					show={show}
					as={Fragment}
					enter="transform ease-in-out duration-300 transition"
					enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
					enterTo="translate-y-0 opacity-100 sm:translate-x-0"
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="pointer-events-auto w-full max-w-sm rounded-lg bg-v2-off-white shadow-lg ring-1 ring-gray-600 ring-opacity-5">
						<div className="p-4 relative">
							<div className="absolute right-2">
								<Clickable
									className="!px-2 !py-0"
									onClick={() => {
										handleSelection(true);
									}}
									variant="naked">
									<span className="sr-only">Close</span>
									<Icon name="closeGray" height={'16'} width={'16'} aria-hidden="true" />
								</Clickable>
							</div>
							<div className="flex items-start">
								<div className="flex-1 pt-4">
									<Typography component="p" className="mt-1 text-sm">
										We use cookies to enhance your browsing experience and provide personalized
										content.
									</Typography>
									<div className="mt-4 grid grid-cols-2 gap-4">
										<Clickable
											className="flex h-10 items-center justify-center text-sm"
											color="gray-600"
											onClick={() => handleSelection(false)}
											variant="outlined">
											Reject
										</Clickable>
										<Clickable
											className="flex h-10 items-center justify-center hover:bg-gray-900 text-sm text-white transition-colors"
											color="gray-600"
											onClick={() => handleSelection(true)}
											variant="filled">
											Accept
										</Clickable>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Transition>
			</div>
		</div>
	);
}

CookiesAlert.propTypes = {
	country: PropTypes.string,
	state: PropTypes.string,
};
