import {
	BUILD_A_PACK,
	PACK_PRODUCT,
	PREPAID_TYPE,
	SUBSCRIPTION_FREQUENCY,
} from '/services/static/attribute-keys';

const getMultiProductDetails = (lineItems, currentPromotion) => {
	if (!currentPromotion || currentPromotion.promotionType !== 'multi-product') {
		return;
	}

	if (!lineItems || lineItems.length === 0) {
		return {
			emptyCart: true,
			quantityFreeQualified: 0,
			quantityNeeded: parseInt(currentPromotion.quantityQualifying),
		};
	}

	let prepaidSubCounted = false;
	let validProductsSummary = lineItems.reduce(
		(memo, lineItem) => {
			const isValidProductType = currentPromotion.productTypes.includes(
				lineItem?.merchandise?.product?.productType,
			);

			const isPackProduct = lineItem.attributes[PACK_PRODUCT] || lineItem.attributes[BUILD_A_PACK];
			const isSubscription = false;

			if (
				isValidProductType &&
				!(
					lineItem.attributes[PREPAID_TYPE] == 'Quarterly' ||
					lineItem.attributes[PREPAID_TYPE] == 'Annually'
				)
			) {
				if (
					lineItem.attributes[PREPAID_TYPE] == 'Quarterly' ||
					lineItem.attributes[SUBSCRIPTION_FREQUENCY]
				) {
					if (!prepaidSubCounted) {
						prepaidSubCounted = true;
						memo.countTotal += 1;
					}
				} else {
					memo.countTotal += lineItem.quantity || 1;
				}

				if (!isSubscription) {
					for (let q = 0; q < lineItem.quantity; q++) {
						memo.allPrices.push(parseFloat(lineItem.merchandise.priceV2.amount));
					}
				}

				if (!isSubscription && !isPackProduct && lineItem.linePrice === 0) {
					memo.countFree += lineItem.quantity || 1;
				} else {
					memo.countNotFree += lineItem.quantity || 1;
				}
			}

			return memo;
		},
		{ allPrices: [], countTotal: 0, countFree: 0, countNotFree: 0 },
	);

	const totalQuantity = validProductsSummary.countTotal;
	const qualifyingQuantity = parseInt(currentPromotion.quantityQualifying);
	const freeQuantity = parseInt(currentPromotion.quantityFree);
	const combinedQuantity = qualifyingQuantity + freeQuantity;

	// Calculate the total number of free products the user qualifies for
	const completeSets = Math.floor(totalQuantity / combinedQuantity);
	const remainder = totalQuantity % combinedQuantity;

	let freeQualified = completeSets * freeQuantity;
	if (remainder >= qualifyingQuantity) {
		freeQualified += Math.min(freeQuantity, remainder - qualifyingQuantity);
	}

	const quantityFreeQualified = freeQualified;

	let quantityNeeded;
	if (totalQuantity < qualifyingQuantity) {
		quantityNeeded = qualifyingQuantity - totalQuantity;
	} else if (totalQuantity % combinedQuantity === 0) {
		quantityNeeded = qualifyingQuantity;
	} else if (totalQuantity % combinedQuantity >= qualifyingQuantity) {
		quantityNeeded = combinedQuantity - (totalQuantity % combinedQuantity);
	} else {
		quantityNeeded = qualifyingQuantity - (totalQuantity % combinedQuantity);
	}

	const nextQuantity = totalQuantity + 1;

	const isOnFifthItem = nextQuantity % 6 === 5;

	const isOnSixthItem = nextQuantity % 6 === 0;

	let pricesFree = validProductsSummary.allPrices.sort((a, b) => a - b);

	pricesFree = pricesFree.slice(0, quantityFreeQualified);

	return {
		emptyCart: false,
		pricesFree,
		quantityFreeAwarded: validProductsSummary.countFree,
		quantityFreeDelta: quantityFreeQualified - validProductsSummary.countFree,
		quantityFreeQualified,
		quantityNeeded,
		nextFree: isOnFifthItem || isOnSixthItem,
	};
};

module.exports = {
	getMultiProductDetails,
};
