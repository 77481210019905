import CustomersAlsoBoughtTwo from '/components/CustomersAlsoBoughtTwo';
import DiscountBasedCartDetail from '/components/promotions/DiscountBasedCartDetail';
import FreeItemCartDetail from '/components/promotions/FreeItemCartDetail';
import FreeShippingBanner from '/components/promotions/FreeShippingBanner';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import LinkCheckout from '/components/LinkCheckout';
import Loading from '/components/Loading';
import Price from '../Price';
import StackingCartDetail from '/components/promotions/StackingCartDetail';
import StackingProductModal from '/components/promotions/StackingProductModal';
import Typography from '/components/Typography';
const Modal = dynamic(() => import('/components/Modal'));
const ModalBody = dynamic(() => import('/components/Modal').then((mod) => mod.ModalBody));
import dynamic from 'next/dynamic';
import { observer } from 'mobx-react-lite';
import { pluralize } from '/utils/pluralize';
import { processor } from '/utils/cart-processor';
import { useCartStore } from '/state/cart-context';
import { useGlobalStore } from '/state/global-context';
import {
	DISCOUNTED_LINE_PRICE,
	LINE_PRICE,
	SUBSCRIPTION_TITLE,
} from '/services/static/attribute-keys';
import { useMemo, useState } from 'react';

const CartPopup = observer(() => {
	const [currentItem, setCurrentItem] = useState();
	const cart = useCartStore();
	const globalStore = useGlobalStore();
	const promotion = globalStore.getPromotionalConfig();
	let badge = '';
	let isSubscriptionCart = false;
	const processedCart = useMemo(() => {
		const processed = processor(cart, promotion);
		if (processed && processed.processedLineItems.length > 0) {
			setCurrentItem(processed.processedLineItems[0]);
		}
		return processed;
	}, [cart.shopifyCart.lines]);

	const price =
		currentItem?.attributes[DISCOUNTED_LINE_PRICE] ||
		currentItem?.attributes[LINE_PRICE] ||
		currentItem?.linePrice;
	const quantity = currentItem?.quantity;
	const compareAtPrice = currentItem?.merchandise?.compareAtPriceV2?.amount * quantity;

	const productObject = {
		pricing: {
			min: price,
		},
		productType: currentItem?.merchandise?.product?.productType,
	};

	if (currentItem?.attributes[SUBSCRIPTION_TITLE]) {
		badge = 'New Subscription';
		isSubscriptionCart = true;
	}

	if (currentItem?.merchandise?.product?.title?.includes('Shipping')) {
		return null;
	}

	const freeShippingThreshold = cart.freeShippingVariant === 'threshold-100' ? 100 : 125;
	const freeShippingActive = cart.freeShippingVariant !== 'control';

	return (
		<Modal id="cart-modal" isOpen={cart.modalOpen} toggle={(val) => cart.setModalOpen(val)}>
			<ModalBody className="mt-20 rounded-t-xl text-v2-brown-darker">
				<div className="bg-v2-off-white relative -mt-4 flex justify-between rounded-t-xl mx-2 md:rounded-t-none  p-4 md:p-10">
					<div className="md:flex items-center justify-start align-middle md:gap-6">
						<Typography variant="heading-md" className="capitalize">
							You Have Excellent Taste
						</Typography>
						<button
							className="flex gap-2 md:border-2 p-1 rounded-lg border-v2-brown-darker"
							onClick={() => {
								cart.setModalOpen(false);
								cart.setDrawerOpen(true);
							}}>
							<Icon name="cart" height="25px" width="25px" />
							<Typography variant="subtitle-md">
								{processedCart.productCount + ' '}
								{pluralize('item', processedCart.productCount)}
							</Typography>
						</button>
					</div>
					{processedCart.subTotal !== 0 &&
						processedCart.subTotal > freeShippingThreshold &&
						freeShippingActive && <span> + Free Shipping</span>}
					<button onClick={() => cart.setModalOpen(false)}>
						<Icon name="closeGray" height="14px" width="14px" />
					</button>
				</div>
				{freeShippingActive && (
					<div className="mx-2">
						<FreeShippingBanner
							processedCart={processedCart}
							subscriptionInCart={isSubscriptionCart}
							freeShippingThreshold={freeShippingThreshold}
						/>
					</div>
				)}
				<div className="mx-2">
					<FreeItemCartDetail processedCart={processedCart} cart={cart} />
				</div>

				<div className="mx-2 bg-v2-off-white">
					<DiscountBasedCartDetail
						processedCart={processedCart}
						subscriptionInCart={isSubscriptionCart}
					/>
					<StackingCartDetail processedCart={processedCart} />
					<StackingProductModal />
				</div>
				{!currentItem ? (
					<div className="w-full bg-v2-off-white h-52 flex items-center align-middle justify-center mx-2">
						<Loading className="ml-2" height="100px" width="100px" />
					</div>
				) : (
					<div className="bg-v2-off-white p-4 mx-2 md:px-10 pb-10">
						<div className="relative flex-col md:flex-row flex">
							<div className="flex md:border-none border-b-2 py-4 mb-2">
								<Image
									src={currentItem.merchandise.image.url}
									height={300}
									width={300}
									objectFit="contain"
									alt={currentItem.merchandise.image.altText}
								/>
								<div className="flex flex-col pl-2">
									<Typography variant="subtitle-sm" className="capitalize">
										{currentItem.merchandise.product.title}
									</Typography>
									<Typography className="capitalize">{currentItem.merchandise.title}</Typography>
									<div className="flex gap-2">
										{compareAtPrice != price && (
											<Typography className="line-through">
												{compareAtPrice.toPrecision(4)}
											</Typography>
										)}
										<Price format={'explicit'} price={price} product={productObject} />
									</div>
									<Typography className="text-primary">{badge}</Typography>
								</div>
							</div>
							<div className="flex flex-col gap-4 md:ml-2 md:pl-2 items-center">
								<div className="h-20 mx-5">
									<div className="flex gap-2 align-middle justify-between pb-4">
										<Typography variant="subtitle-xs">Subtotal </Typography>
										<Price format={'explicit'} price={processedCart.subTotal} />
									</div>
									<LinkCheckout cart={cart} processedCart={processedCart} />
								</div>
								<button
									className="flex items-center align-middle justify-center gap-2"
									onClick={() => cart.setModalOpen(false)}>
									Continue Shopping <Icon name="arrowLeft" height="15px" width="15px" />
								</button>
								<button
									className="underline text-v2-hunter-green -mt-2"
									onClick={() => {
										cart.setModalOpen(false);
										cart.setDrawerOpen(true);
									}}>
									View Full Cart
								</button>
							</div>
						</div>

						<div>
							<CustomersAlsoBoughtTwo
								header={
									<Typography
										className="capitalize my-4 text-left"
										component="div"
										variant="heading-md">
										Goes Well With
									</Typography>
								}
								onClick={() => cart.setModalOpen(false)}
								location="popup"
								product={{ id: currentItem.merchandise.product.id }}
								sizeSelected={currentItem.merchandise.title}
							/>
						</div>
					</div>
				)}
			</ModalBody>
		</Modal>
	);
});

export default CartPopup;
