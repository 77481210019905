import { SIZES_ALL } from '../../services/static/sizes';
import { encodeGid } from '/utils/graph-ql';
import { getAuxillarySellingPlanData } from '/services/static/subscription-v2/auxillary-selling-plan-data';
import { sortByOrder } from '../common-utils';

const formatProduct = (product) => {
	if (!product) {
		return;
	}

	let images = [];
	if (product.images) {
		images = product.images.edges.map((i) => i.node);
	}

	const titles = product.title?.split('|') || [];

	const isMysteryProduct = product.title?.includes('The Mystery');

	let variants = product.variants?.edges?.reduce((memo, variant) => {
		if (!variant.node) {
			return memo;
		}

		const formatted = JSON.parse(JSON.stringify(variant.node));

		// temporary hack to set the dc inventory for mystery to the sum of its children,
		// instead of what is inside the big box of mystery products at the dc
		if (isMysteryProduct) {
			if (!formatted.dcInventory) {
				formatted.dcInventory = {};
			}

			formatted.dcInventory.value = formatted.quantityAvailable;
		}

		// unsure if there's a way to see "inventory not tracked" in the shopify payload, putting this in
		// to get the site working again.
		if (product?.productType === 'Gift Cards') {
			formatted.quantityAvailable = 999;
			formatted.dcInventory = {
				value: 999,
			};
		}

		memo.push(formatted);

		return memo;
	}, []);

	if (product?.productType?.includes('Boxer')) {
		variants = sortByOrder(variants, SIZES_ALL, 'title');
	}

	let prices = [];
	if (product.priceRange) {
		prices = [product.priceRange.minVariantPrice.amount, product.priceRange.maxVariantPrice.amount];
	}

	let compareAtPrices = [];
	if (product.compareAtPriceRange) {
		compareAtPrices = [
			product.compareAtPriceRange.minVariantPrice.amount,
			product.compareAtPriceRange.maxVariantPrice.amount,
		];
	}

	// distill the nested list of sellingPlanGroups/sellingPlans into a flat array of sellingPlans
	// with the data we need (shopify data enhanced by auxillary selling plan data).
	let sellingPlans = [];
	let subscriptionCompareAtPrice;
	const enableSubscribe = true; //!product.productType.includes('Sock');
	if (product.sellingPlanGroups?.edges && enableSubscribe) {
		sellingPlans = product.sellingPlanGroups.edges.reduce((memo, sellingPlanGroup) => {
			if (
				!sellingPlanGroup.node.name ||
				sellingPlanGroup.node.name.toLowerCase() === 'subscribe and save' ||
				sellingPlanGroup.node.name.includes('2022-09-06') ||
				!sellingPlanGroup.node.sellingPlans ||
				!sellingPlanGroup.node.sellingPlans.edges ||
				sellingPlanGroup.node.sellingPlans.edges.length === 0 ||
				product.productType === 'Boxers - Cooling - Fly' ||
				product.productType === 'Thongs - Cooling'
			) {
				return memo;
			}

			sellingPlanGroup.node.sellingPlans.edges.forEach((sellingPlan) => {
				const auxillaryData = getAuxillarySellingPlanData(
					sellingPlan.node.name,
					product.productType,
				);

				let recurringPrice;
				if (sellingPlan.node?.priceAdjustments?.length === 1) {
					recurringPrice = sellingPlan.node.priceAdjustments[0]?.adjustmentValue?.price?.amount;
				}
				let priceRegular;
				if (prices) {
					priceRegular = prices[0];
				}

				const formattedSellingPlan = {
					id: encodeGid(sellingPlan.node.id),
					name: sellingPlan.node.name,
					priceRegular,
					recurringPrice,
					...(auxillaryData || {}),
				};

				if (auxillaryData && auxillaryData.key === 'monthly') {
					subscriptionCompareAtPrice = recurringPrice;
				}

				memo.push(formattedSellingPlan);
			});

			return memo;
		}, []);
	}

	const formattedProduct = {
		...product,
		images,
		prices,
		compareAtPrices,
		sellingPlans,
		titles,
		variants,
	};

	if (subscriptionCompareAtPrice) {
		formattedProduct.subscriptionCompareAtPrice = subscriptionCompareAtPrice;
	}

	return formattedProduct;
};

module.exports = {
	formatProduct,
};
