import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

const Modal = dynamic(() => import('/components/Modal'));
const ModalBody = dynamic(() => import('/components/Modal').then((mod) => mod.ModalBody));

const PromotionModal = ({
	imageSrcDesktop,
	imageSrcMobile,
	isModalOpen,
	setIsModalOpen,
	termsLink,
}) => {
	return (
		<Modal
			id="promotion-modal"
			isOpen={isModalOpen}
			toggle={(val) => setIsModalOpen(val)}
			customType="stacking">
			<ModalBody>
				<button
					type="button"
					className="fixed md:absolute mt-2 md:mt-6 mb-10 ml-4 p-2 bg-v2-off-white rounded-full z-20"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => setIsModalOpen(false)}>
					<Icon name="close" height="15px" width="15px" />
				</button>
				<Clickable
					onClick={() => setIsModalOpen(false)}
					className="hidden relative md:block text-center mt-16 h-[39rem] w-full">
					<Image
						alt="modal"
						layout="fill"
						objectFit="contain"
						objectPosition="top"
						src={imageSrcDesktop}
					/>
					<div className="w-16 rounded-full text-center text-primary absolute -top-8 right-2 bg-v2-off-white font-bold">
						<Clickable linkToInternal={termsLink}>Terms</Clickable>
					</div>
				</Clickable>
				<Clickable
					onClick={() => setIsModalOpen(false)}
					className="md:hidden relative top-0 text-center mt-10 h-[75vh] w-full">
					<Image
						alt="modal"
						layout="fill"
						objectFit="contain"
						objectPosition="top"
						src={imageSrcMobile}
					/>
					<div className="w-16 rounded-full text-center text-primary ml-2 absolute -top-8 right-2 bg-v2-off-white font-bold">
						<Clickable linkToInternal={termsLink}>Terms</Clickable>
					</div>
				</Clickable>
			</ModalBody>
		</Modal>
	);
};

PromotionModal.propTypes = {
	imageSrcDesktop: PropTypes.string,
	imageSrcMobile: PropTypes.string,
	isModalOpen: PropTypes.bool,
	setIsModalOpen: PropTypes.func,
	termsLink: PropTypes.string,
};

export default PromotionModal;
