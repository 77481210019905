import GET_PRODUCTS_FOR_COLLECTION from './products-for-collection';
import GET_PRODUCTS_METADATA from './product-metadata';
import GET_PRODUCT_BY_HANDLE from '/services/queries/shopify/product-by-handle';
import GET_PRODUCT_BY_ID from './product-by-id';
import GET_PRODUCT_PRICE from './price-by-id';
import GET_RECOMMENDED_PRODUCTS from './recommended-products';
import GET_SELLING_PLANS from './selling-plans';
import GET_VARIANT_INVENTORY from './variant-inventory';

module.exports = {
	GET_PRODUCTS_FOR_COLLECTION,
	GET_PRODUCTS_METADATA,
	GET_PRODUCT_PRICE,
	GET_PRODUCT_BY_HANDLE,
	GET_PRODUCT_BY_ID,
	GET_RECOMMENDED_PRODUCTS,
	GET_SELLING_PLANS,
	GET_VARIANT_INVENTORY,
};
