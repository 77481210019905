import Button from '/components/Button';
import Clickable from '../../Clickable';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import Loading from '/components/Loading';
import ProductOptions from '../../products/show/product-form/ProductOptions';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getProductById } from '/services/products';
import { linesFromVariants } from '/utils/product';
import { logTryCatch } from '/utils/logging';
import { parseGid } from '/utils/graph-ql';
import { useCartStore } from '/state/cart-context';
import {
	ADD_TO_CART_ID,
	AUTO_REMOVE,
	CAN_CHANGE_QUANTITY,
	PROMOTIONAL_ITEM,
	PROMOTIONAL_ITEM_INTERACTION,
	PROMOTIONAL_TIER,
} from '/services/static/attribute-keys';
import { useEffect, useState } from 'react';

const TierForm = ({ allTiers, setModalOpen, tier, totalTiers }) => {
	const cartStore = useCartStore();
	const [currentTier, setCurrentTier] = useState(tier);
	const [productsToShow, setProductsToShow] = useState([]);
	const [additionalProductsToShow, setAdditionalProductsToShow] = useState([]);
	const [currentTierProduct, setCurrentTierProduct] = useState();
	const [primaryProduct, setPrimaryProduct] = useState();
	const [isBackup, setIsBackup] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [additionalSelectedOptions, setAdditionalSelectedOptions] = useState([]);
	const [selectedProductType, setSelectedProductType] = useState();
	const [selectedBackup, setSelectedBackup] = useState();
	const [soldOutSizes, setSoldOutSizes] = useState([]);
	const isSwappableBackup =
		currentTierProduct &&
		currentTierProduct?.backups &&
		currentTierProduct?.backups[0]?.isSwappable &&
		currentTierProduct?.backups[0].swappableProducts &&
		isBackup;

	const [addToCartLoading, setAddToCartLoading] = useState(false);

	useEffect(() => {
		if (currentTier?.productConfigs[0].primary) {
			setProduct([currentTier.productConfigs[0].primary.id], false);
			if (currentTier.productConfigs[0].primary.additionalLines?.length > 0) {
				setAdditionalProduct([currentTier.productConfigs[0].primary.additionalLines[0].id]);
			}
			setSelectedProductType(currentTier.productConfigs[0].primary.productType);
		}
	}, [currentTier]);

	useEffect(() => {
		if (
			!productsToShow ||
			!productsToShow[0]?.variants ||
			productsToShow[0].variants.length === 0
		) {
			return;
		}
		setSelectedOptions([]);
		setAdditionalSelectedOptions([]);
		const soldOutSizeTitles = productsToShow[0].variants.reduce((memo, variant) => {
			if (variant.quantityAvailable <= 0) {
				memo.push(variant.title);
			}
			return memo;
		}, []);

		setSoldOutSizes(soldOutSizeTitles);
	}, [productsToShow]);

	const addToCart = async () => {
		setAddToCartLoading(true);

		const lineAttributes = [
			{ key: CAN_CHANGE_QUANTITY, value: 'false' },
			{ key: PROMOTIONAL_ITEM, value: 'true' },
			{ key: PROMOTIONAL_ITEM_INTERACTION, value: 'true' },
			{ key: PROMOTIONAL_TIER, value: currentTier.title },
			{ key: ADD_TO_CART_ID, value: currentTier.title },
			{ key: AUTO_REMOVE, value: 'true' },
		];

		const allOptions = [...selectedOptions, ...additionalSelectedOptions];
		const variantLineData = allOptions.map((option) => {
			return {
				attributes: lineAttributes,
				price: option.product?.prices[0] || '',
				product: option.product,
				quantity: 1,
				variantId: option.variantId,
				variantTitle: option.variantTitle || '',
			};
		});

		const lines = linesFromVariants(variantLineData);

		await cartStore
			.addItems({
				error: () => {
					setAddToCartLoading(false);
					setModalOpen(false);
				},
				lines,
				success: () => {
					setAddToCartLoading(false);
					setModalOpen(false);
				},
			})
			.catch((e) => {
				setAddToCartLoading(false);
				logTryCatch(e);
				setModalOpen(false);
			});
	};

	const toggleBackupProduct = async () => {
		setSelectedOptions([]);
		setAdditionalSelectedOptions([]);
		setIsBackup(true);
		const currentId = parseGid(productsToShow[0].id);

		if (
			!productsToShow ||
			!currentId ||
			!currentTier.productConfigs ||
			currentTier.productConfigs.length === 0
		) {
			return;
		}

		const tierProduct = currentTier.productConfigs.find((tierProd) => {
			return tierProd.primary.handle === productsToShow[0].handle;
		});

		if (!tierProduct || !tierProduct.primary.id || !tierProduct.backups) {
			return;
		}

		setPrimaryProduct({
			id: currentId,
			title: productsToShow[0].titles[0],
		});

		let productIds = [tierProduct.backups[0].id];
		if (tierProduct?.backups[0]?.isMultiLine) {
			productIds.push(tierProduct?.backups[0].additionalLines[0]?.id);
		}
		if (
			currentTierProduct &&
			currentTierProduct?.backups[0]?.isSwappable &&
			currentTierProduct?.backups[0].swappableProducts
		) {
			setSelectedBackup(currentTierProduct?.backups[0].productType);
		}
		setProduct(productIds, true);
	};

	const togglePrimaryProduct = async () => {
		setSelectedOptions([]);
		setAdditionalSelectedOptions([]);
		setProduct([primaryProduct.id]);
		setIsBackup(false);
	};

	const setProduct = async (productIds, isBackup = false) => {
		const promises = productIds.map((productId) => {
			return getProductById(productId?.replace('gid://shopify/Product/', ''));
		});
		const products = await Promise.all(promises);

		if (!isBackup) {
			setPrimaryProduct();
		}

		setProductsToShow([products[0]]);

		const tierProduct = tier?.productConfigs?.find((tierProd) => {
			return (
				tierProd.primary.handle === products[0].handle ||
				(tierProd.backups && tierProd.backups[0]?.handle === products[0].handle)
			);
		});

		if (tierProduct) {
			setCurrentTierProduct(tierProduct);
			if (!isBackup && tierProduct.primary.additionalLines?.length > 0) {
				setAdditionalProduct([tierProduct.primary.additionalLines[0].id]);
			} else {
				setAdditionalProductsToShow([]);
				setAdditionalSelectedOptions([]);
			}
		}
	};

	const setAdditionalProduct = async (productIds) => {
		const promises = productIds.map((productId) => {
			return getProductById(productId?.replace('gid://shopify/Product/', ''));
		});
		const products = await Promise.all(promises);
		setAdditionalProductsToShow(products);
	};

	function getProductIds(config) {
		const productIds = [config.id];
		if (config.isMultiLine && config.additionalLines[0]) {
			productIds.push(config.additionalLines[0]?.id);
		}
		return productIds;
	}

	function skipGift() {
		const currentIndex = allTiers.findIndex((tier) => tier.title === currentTier.title);
		if (totalTiers > 1 && currentIndex + 1 < totalTiers) {
			setCurrentTier(allTiers[currentIndex + 1]);
		} else {
			setModalOpen(false);
		}
	}

	const canAddToCart =
		selectedOptions.length > 0 &&
		(!additionalProductsToShow.length || additionalSelectedOptions.length > 0 || isBackup);

	return (
		<div className="flex flex-col bg-v2-off-white min-h-screen md:min-h-0">
			{isSwappableBackup && (
				<div className="px-4 md:px-8 py-4">
					<Typography className="mb-4" variant="heading-lg">
						Pick Your Item
					</Typography>
					<div className="flex gap-2">
						<Button
							onClick={() => {
								setProduct(getProductIds(currentTierProduct?.backups[0]), false);
								setSelectedBackup(currentTierProduct?.backups[0].productType);
							}}
							className={classNames(
								'rounded !shadow-none',
								selectedBackup === currentTierProduct?.backups[0].productType
									? '!bg-[#2F3337] !text-white'
									: '!bg-[#F2F3F5] !text-black',
							)}
							variant="filled-gray"
							type="button">
							{currentTierProduct?.backups[0].productType}
						</Button>
						<Button
							onClick={() => {
								setProduct(
									getProductIds(currentTierProduct?.backups[0].swappableProducts[0]),
									false,
								);
								setSelectedBackup(currentTierProduct?.backups[0].swappableProducts[0].productType);
							}}
							className={classNames(
								'rounded !shadow-none',
								selectedBackup === currentTierProduct?.backups[0].swappableProducts[0].productType
									? '!bg-[#2F3337] !text-white'
									: '!bg-[#F2F3F5] !text-black',
							)}
							variant="filled-gray"
							type="button">
							{currentTierProduct?.backups[0].swappableProducts[0].productType}
						</Button>
					</div>
				</div>
			)}

			<div className="flex-1">
				{productsToShow.map((currentProduct, i) => (
					<div key={i} className="flex flex-col md:flex-row md:gap-8 p-4 md:p-8">
						<div className="w-full md:w-1/2 flex flex-col">
							<Typography className="mb-4 md:hidden" variant="heading-lg">
								{currentProduct?.titles[0] || currentTier.title}
							</Typography>

							<div className="relative aspect-square w-full mb-2">
								<Image
									alt={currentTier.title}
									objectFit="cover"
									src={
										currentProduct && currentProduct.images[0]
											? currentProduct.images[0].src
											: currentTier.imageSrcCover
									}
									layout="fill"
									className="rounded-lg"
								/>
							</div>

							<Typography className="mb-4 text-gray-600" variant="label-lg">
								{currentProduct?.productType}
							</Typography>
						</div>

						<div className="w-full md:w-1/2">
							<Typography className="hidden md:block mb-2" variant="heading-lg">
								{currentProduct?.titles[0] || currentTier.title}
							</Typography>

							{currentTier.productConfigs.length > 1 && (
								<div className="mb-6">
									<Typography className="mb-4" variant="heading-sm">
										Pick Your Item
									</Typography>
									<div className="flex flex-wrap gap-2">
										{currentTier.productConfigs.map((product, i) => (
											<Button
												key={i}
												onClick={() => {
													setProduct([product.primary.id], false);
													setSelectedProductType(product.primary.productType);
												}}
												className={classNames(
													'rounded !shadow-none border-2 border-v2-brown-darker',
													selectedProductType === product.primary.productType
														? '!bg-v2-brown text-v2-off-white'
														: '!bg-v2-off-white !text-black text-v2-brown-darker',
												)}
												variant="filled-gray"
												type="button">
												{product.primary.productType}
											</Button>
										))}
									</div>
								</div>
							)}

							{currentProduct && (
								<div className="w-full">
									<ProductOptions
										product={currentProduct}
										selectedOptions={selectedOptions}
										setSelectedOptions={setSelectedOptions}
									/>
								</div>
							)}
						</div>
					</div>
				))}

				{!isBackup &&
					additionalProductsToShow.map((additionalProduct, i) => (
						<div key={i} className="flex flex-col md:flex-row md:gap-8 p-4 md:p-8">
							<div className="w-full md:w-1/2 flex flex-col">
								<Typography className="mb-4 md:hidden" variant="heading-lg">
									{additionalProduct?.titles[0]}
								</Typography>

								<div className="relative aspect-square w-full mb-2">
									<Image
										alt={additionalProduct?.titles[0]}
										objectFit="cover"
										src={additionalProduct?.images[0]?.src}
										layout="fill"
										className="rounded-lg"
									/>
								</div>

								<Typography className="mb-4 text-gray-600" variant="label-lg">
									{additionalProduct?.productType}
								</Typography>
							</div>

							<div className="w-full md:w-1/2">
								<Typography className="hidden md:block mb-2" variant="heading-lg">
									{additionalProduct?.titles[0]}
								</Typography>

								{additionalProduct && (
									<div className="w-full">
										<ProductOptions
											product={additionalProduct}
											selectedOptions={additionalSelectedOptions}
											setSelectedOptions={setAdditionalSelectedOptions}
										/>
									</div>
								)}
							</div>
						</div>
					))}
			</div>

			{soldOutSizes.length > 0 && currentTierProduct && currentTierProduct.backups && (
				<div className="px-4 md:px-8">
					<Typography variant="body-lg">
						<Clickable
							className="text-secondary-dark underline capitalize"
							onClick={toggleBackupProduct}>
							Don&apos;t see your size? Pick a different gift
						</Clickable>
					</Typography>
				</div>
			)}

			{primaryProduct && (
				<div className="px-4 md:px-8 mt-2">
					<Clickable className="font-semibold flex items-center" onClick={togglePrimaryProduct}>
						<Icon className="mr-2" name="caretLeft" height="10px" width="10px" />
						<Typography className="text-secondary-dark" variant="body-lg">
							Nah, back to {primaryProduct.title}
						</Typography>
					</Clickable>
				</div>
			)}

			<div className="sticky relative bottom-0 w-full bg-v2-off-white shadow-lg md:shadow-none p-4 md:p-8 mt-8 z-20">
				{additionalProductsToShow?.length > 0 && (
					<div className="h-[900px]:hidden absolute bottom-40 left-1/2 -translate-x-1/2 rounded-full p-2 shadow-lg active:shadow-md active:translate-y-0.5 transition-all bg-v2-hunter-green text-v2-off-white">
						More Options Below
					</div>
				)}
				<div className="max-w-md mx-auto space-y-2">
					<Clickable
						className="flex justify-center items-center w-full"
						disabled={!canAddToCart}
						onClick={addToCart}
						variant="filled">
						<Typography variant="body-lg">
							{totalTiers > 1 ? 'Pick your next gift' : 'Add to Cart'}
						</Typography>
						{addToCartLoading && <Loading className="ml-2" />}
					</Clickable>
					<Clickable
						className="flex justify-center items-center w-full"
						onClick={skipGift}
						variant="inverted">
						<Typography variant="body-lg">Decline Gift</Typography>
					</Clickable>
				</div>
			</div>
		</div>
	);
};

TierForm.propTypes = {
	allTiers: PropTypes.array,
	setModalOpen: PropTypes.func,
	tier: PropTypes.object,
	totalTiers: PropTypes.number,
};

export default TierForm;
