import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import Loading from '/components/Loading';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { addDiscountCode } from '/services/cart';
import dynamic from 'next/dynamic';
import { getElevarCheckoutData } from '/services/analytics/elevar';
import { getShinestyCheckoutData } from '/services/analytics/shinesty';
import { submitEvent } from '/services/analytics/shinesty';
import { toJS } from 'mobx';
import { trackInitiateCheckout } from '/services/analytics/shinesty-analytics';
import { useDiscountCodeStore } from '/state/discount-code-context';
import { useState } from 'react';
import { useUtmStore } from '/state/utm-context';
import { v4 as uuidv4 } from 'uuid';

import {
	FREE_ITEM_QUANTITY,
	IS_FOURGY,
	IS_FREE_ITEM,
	IS_OPTED_OUT,
	PROMOTIONAL_ITEM,
	PROMOTIONAL_TIER,
} from '/services/static/attribute-keys';
import { createCart as createLoopCart, getLoopReturnsParams } from '/services/loop-returns';
import { log, logJson } from '/services/discounts/logging';

const Modal = dynamic(() => import('/components/Modal'));
const ModalBody = dynamic(() => import('/components/Modal').then((mod) => mod.ModalBody));

const LinkCheckout = ({ cart, processedCart }) => {
	const [checkoutLoading, setCheckoutLoading] = useState(false);
	const [checkoutHref, setCheckoutHref] = useState();
	const [discountCode, setDiscountCode] = useState();
	const [discountCodeModalOpen, setDiscountCodeModalOpen] = useState(false);
	const [bypassDiscountCodeCheck, setBypassDiscountCodeCheck] = useState(false);

	const discountCodeStore = useDiscountCodeStore();
	const utmStore = useUtmStore();

	if (!processedCart?.shopifyCart?.id || !processedCart?.hasItems) {
		return null;
	}

	//uncomment for multi product sale
	const updateLineAttributes = async (lines) => {
		const updates = [];
		lines.forEach((line) => {
			const arr = [];
			for (let key in line.attributes) {
				arr.push({ key: key, value: line.attributes[key] });
			}
			updates.push({
				id: line.id,
				attributes: arr,
			});
		});
		await cart.updateItems(updates);
	};

	const checkout = async () => {
		setCheckoutLoading(true);

		const cartAttributes = [
			...getElevarCheckoutData(utmStore),
			...getShinestyCheckoutData(utmStore),
		];

		const utmHistory = utmStore.getUtmHistory();

		if (utmHistory) {
			cartAttributes.push({
				key: '_utm_history',
				value: utmHistory,
			});
		}

		if (cart.freeShippingVariant && cart.freeShippingVariant != '') {
			cartAttributes.push({
				key: '__freeShippingVariant',
				value: cart.freeShippingVariant,
			});
		}

		// processedCart.processedLineItems.forEach((lineItem) => {
		// 	lineItem.attributes[IS_FREE_ITEM] = 'false';
		// });

		processedCart.processedLineItems.forEach((lineItem) => {
			if (lineItem.linePrice == 0) {
				lineItem.attributes[IS_FREE_ITEM] = 'true';
				if (!lineItem?.attributes[PROMOTIONAL_ITEM]) {
					lineItem.attributes[IS_FOURGY] = 'true';
				}
				lineItem.attributes[FREE_ITEM_QUANTITY] = String(lineItem.quantity);
			}
		});

		await updateLineAttributes(processedCart.processedLineItems);
		await cart.addCartAttributes(cartAttributes);
		await cart.updateCartWithPromotions();
		await precheckoutValidatePromotionalItems(cart);

		// pass initiate checkout event from link click to checkout.

		await submitEvent(
			{
				contentIds: processedCart?.processedLineItems?.map((item) => {
					return item.merchandise?.product?.id?.replace('gid://shopify/Product/', '');
				}),
			},
			uuidv4(),
			'initiateCheckout',
		);

		trackInitiateCheckout(processedCart);

		const checkoutUrl = (cart.shopifyCart.id && cart.shopifyCart.checkoutUrl) || '#';
		setCheckoutHref(checkoutUrl);

		if (checkoutUrl.indexOf('/cart') > -1) {
			await submitEvent(
				{
					cartId: cart.shopifyCart.id,
					checkoutUrl: cart.shopifyCart.checkoutUrl,
				},
				uuidv4(),
				'shopifyInvalidCartUrl',
			);
		}

		const loopParams = getLoopReturnsParams();

		if (loopParams) {
			const loopCart = await createLoopCart(processedCart);

			if (loopCart.success && loopCart.checkoutUrl) {
				window.location = loopCart.checkoutUrl;
				await cart.clearCart();
				setCheckoutLoading(false);
				return;
			}
		}

		const cartDiscountCode = discountCodeStore.getCartDiscountCode(processedCart);

		if (cartDiscountCode) {
			log('Discount code found, sending to shopify cart');
			const updatedCartResponse = await addDiscountCode(
				processedCart.shopifyCart?.id,
				cartDiscountCode,
			);

			const returnedCodes =
				updatedCartResponse?.cartDiscountCodesUpdate?.cart?.discountCodes?.map((returnedCode) => {
					return returnedCode?.code;
				}) || [];

			logJson('Shopify cart update complete, here are the codes on the cart', returnedCodes);

			if (!bypassDiscountCodeCheck && !returnedCodes.includes(cartDiscountCode)) {
				log(
					'Returned codes does not include our discount code, opening the modal and blocking the link',
				);

				setDiscountCode(cartDiscountCode);
				setDiscountCodeModalOpen(true);
				setCheckoutLoading(false);
				setBypassDiscountCodeCheck(true);
				return;
			}
		}

		window.location = checkoutUrl;
		setCheckoutLoading(false);
	};

	const precheckoutValidatePromotionalItems = async (cart) => {
		const lines = toJS(cart?.shopifyCart?.lines?.edges);
		const lineIds = lines.reduce(
			(memo, line) => {
				const promotionalTierAttribute = line.node.attributes.find((attribute) => {
					return attribute.key === IS_OPTED_OUT;
				});

				if (promotionalTierAttribute?.value === 'true') {
					memo.idsToRemove.push(line.node.id);
				}

				const promotionalTier = line.node.attributes.find((attribute) => {
					return attribute.key === PROMOTIONAL_TIER;
				});

				if (promotionalTier) {
					if (
						memo.tiersInCart.indexOf(promotionalTier?.value) > -1 &&
						promotionalTier?.value !== 'Goldilocks Hoodie & Jogger Set'
					) {
						memo.idsToRemove.push(line.node.id);
					} else {
						memo.tiersInCart.push(promotionalTier?.value);
					}
				}

				if (promotionalTier && line.node.quantity > 1) {
					memo.idsToUpdate.push({
						id: line.node.id,
						quantity: 1,
					});
				}

				return memo;
			},
			{ idsToRemove: [], tiersInCart: [], idsToUpdate: [] },
		);

		const toUpdate = lineIds['idsToUpdate'];

		if (toUpdate.length > 0) {
			await cart.updateItems(toUpdate, true);
		}

		const toRemove = lineIds['idsToRemove'];

		if (toRemove.length > 0) {
			await cart.removeItems({ lineIds: toRemove, skipPromotions: true });
		}
	};

	let isSub = processedCart.hasSubscriptionItem | false;

	return (
		<>
			<Clickable
				className="flex items-center justify-center flex-grow"
				variant="filled"
				size="lg"
				onClick={() => {
					checkout();
				}}
				title="checkout"
				disabled={!cart.shopifyCart || !cart.shopifyCart.checkoutUrl}
				heapEventName="Checkout Button Click"
				heapEventData={{
					Location: 'Minicart',
					'Is Subscription': isSub,
					'Cart Value': processedCart?.shopifyCart?.estimatedCost?.subtotalAmount?.amount,
				}}>
				<Typography component="p" variant="body">
					Checkout
				</Typography>
				{checkoutLoading && <Loading height="16px" width="16px" className="ml-2" />}
			</Clickable>
			<Modal
				id="discount-code-alert"
				isOpen={discountCodeModalOpen}
				toggle={(val) => setDiscountCodeModalOpen(val)}
				customType="conjured">
				<ModalBody>
					<div className="p-4 bg-primary relative">
						<Typography className="pt-8 md:pt-0 text-white" component="h1" variant="subtitle-lg">
							Oops!
						</Typography>
						<button
							type="button"
							className="absolute top-0 right-2 mt-4 mb-10 ml-4 p-2 bg-v2-off-white rounded-full"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => setDiscountCodeModalOpen(false)}>
							<Icon name="close" height="15px" width="15px" />
						</button>
					</div>
					<div className="p-4 bg-v2-off-white relative">
						<Typography component="p" variant="body">
							We weren&apos;t able to auto apply your discount code and the math ain&apos;t
							mathin&apos;. Just use code {discountCode} at checkout to get your discount.
						</Typography>
						<Clickable
							className="mt-8 w-full"
							onClick={() => {
								// window.location = checkoutHref;
								window.open(checkoutHref);
							}}
							variant="filled">
							Continue to Checkout
						</Clickable>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

LinkCheckout.propTypes = {
	cart: PropTypes.object,
	processedCart: PropTypes.object,
};

export default LinkCheckout;
