import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const ProductOptionButton = forwardRef(
	(
		{
			alwaysAvailable = false,
			displayValue,
			name,
			onSelect,
			product,
			selected = false,
			value,
			variant,
		},
		ref,
	) => {
		let isDisabled = !variant;
		const inventoryAvailable =
			variant.quantityAvailable > 0 &&
			variant?.dcInventory !== null &&
			variant?.dcInventory?.value !== '0';

		if (alwaysAvailable === false && inventoryAvailable === false && variant.title !== 'none') {
			isDisabled = true;
		}

		let styles =
			'relative text-center text-base font-bold uppercase py-[9px] px-4 rounded mr-2 mb-2 cursor-pointer tracking-wider min-w-[54px] ';

		if (isDisabled && selected) {
			styles += 'cursor-default outline outline-2 outline-v2-brown';
		} else {
			if (selected) {
				styles += 'text-gray-100 bg-v2-brown cursor-default border-v2-brown-darker border-2';
			} else {
				styles += 'text-v2-brown-darker border-v2-brown-darker border-2';
			}
		}

		return (
			<label>
				<div className={styles} id="size_select">
					<span>{displayValue || value}</span>
					{isDisabled && (
						<img
							className="absolute h-[20px] top-[-5px] right-[-5px]"
							alt="sold out"
							src="https://cdn.shinesty.com/2023-08-25/oos-notify-chat.svg"
						/>
					)}
				</div>

				<input
					className="absolute top-0 left-2 invisible"
					ref={ref}
					type="radio"
					id={`${name}_${value}`}
					name={name}
					value={value}
					onClick={() => onSelect(product, variant, isDisabled)}
				/>
			</label>
		);
	},
);

ProductOptionButton.displayName = 'ProductOption';

ProductOptionButton.propTypes = {
	alwaysAvailable: PropTypes.bool,
	displayValue: PropTypes.string,
	name: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired,
	product: PropTypes.object,
	selected: PropTypes.bool,
	value: PropTypes.string.isRequired,
	variant: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default ProductOptionButton;
